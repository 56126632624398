import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPlace, fetchPlaces } from './placeApi'

const initialState = {
  selAncestorBirthplace: [],
  selAncestorDeathplace: [],
  allPlaces: [],
  addPlaceDialogOpen: false,
};

export const setSelAncestorBirthPlace = createAsyncThunk(
  'places/setSelAncestorBirthPlace',
  async (placeId: string) => {
    const response = await fetchPlace(placeId);
    return await response
  }
);

export const fetchAllPlaces = createAsyncThunk(
  'places/fetchAllPlaces',
  async (placeId: string) => {
    const response = await fetchPlaces();
    return await response
  }
);

export const setSelAncestorDeathPlace = createAsyncThunk(
  'places/setSelAncestorDeathPlace',
  async (placeId: string) => {
    const response = await fetchPlace(placeId);
    return await response
  }
);

export const placeSlice = createSlice({
    name: 'places',
    initialState,
    reducers: {
         clearPlaces: (state, action) => {
           state.selAncestorBirthplace = null 
           state.selAncestorDeathplace = null
        },
         setAddPlaceDialogState: (state, action) =>{
          state.addPlaceDialogOpen = action.payload;
        }    
    },
    extraReducers: (builder) => {
    builder
      .addCase(setSelAncestorBirthPlace.fulfilled, (state, action) => {
        state.selAncestorBirthplace = action.payload;
      })
      .addCase(setSelAncestorDeathPlace.fulfilled, (state, action) => {
         state.selAncestorDeathplace = action.payload;
      })
      .addCase(fetchAllPlaces.fulfilled, (state, action) =>{
        state.allPlaces = action.payload;
      });
  },
});

export const selAncestorBirthplace = (state) => state.places.selAncestorBirthplace || [];
export const selAncestorDeathplace = (state) => state.places.selAncestorDeathplace || [];
export const addPlaceDialogOpen = (state) => state.places.addPlaceDialogOpen;
export const allPlaces = (state) => state.places.allPlaces || [];
export const { clearPlaces, setAddPlaceDialogState } = placeSlice.actions;
export default placeSlice.reducer;