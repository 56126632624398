import 'isomorphic-fetch'
import config from '../../../env/config'

export const fetchPlace = async (placeId) => {
    console.log('IN PLACE API ' + placeId)
    return fetch(config.ENDPOINT + '/places/' + placeId, 
            {method: 'get'})
            .then((res) => res.json())
            .then((res) => res)
    
}

export const fetchPlaces = async () => {
    return fetch(config.ENDPOINT + '/placeservice/fetchAllPlaces', 
            {method: 'get'})
            .then((res) => res.json())
            .then((res) => res.data)
}
