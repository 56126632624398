import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AncestorMenu from '../menus/AncestorMenu'
import {
  fetchAll,
  selectAncestors,
  fetchAsync
} from './../../containers/ancestor/ancestorSlice';

export default function HomePage() {
  //const ancestors = useSelector(selectAncestors);
  //const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(fetchAsync());
  // }, []);


  // let lastNames = [...new Set(data.map(item => item.lastName))];
 
 return (
    <div>

      Home Page

      {/* <AncestorMenu ancestors={ancestors} /> */}
    
        {/* {ancestors.map(function(ancestor, index){
                return <div key={index}>{ancestor.firstName} ' ' {ancestor.lastName}</div>
            })
        } */}

    </div>
 )

}


