
import 'isomorphic-fetch'
import config from '../../../env/config'

export const uploadImage = async (args) => {
    console.log('passing args to uploadImage');
    console.log(args);
    let ft = args.filetype.split('/');

    console.log('FILETYPE IS ' + ft[1]);
    let bod = JSON.stringify({image: args.file})
    return fetch(config.ENDPOINT + '/imageservice/uploadimageBase64/' + args.parentId + '/' + args.sequence +'/' + args.subheading + '/' + ft[1],     
            {method: 'post',
            body: bod,
            headers: new Headers({'Authorization': 'Bearer ' + args.token,
                                  'content-type': 'application/json'})
            })
            .then((res) => res.json())
            .then((res) =>{ return res.data})
}

export const deleteImage = async(args) => {
        console.log('args for deleteImage')
        console.log(args);
        return fetch(config.ENDPOINT + '/imageservice/deleteImage/' + args.imageId,     
            {method: 'post',
             headers: new Headers({'Authorization': 'Bearer ' + args.token,
                                  'content-type': 'application/json'})
            })
            .then((res) => res.json())
            .then((res) =>{ return res})
}
