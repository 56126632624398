import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'

import { createAction } from '@reduxjs/toolkit'

 

const persist = () => (store) => (next) => (action) => {

  const result = next(action)

 

  if (action.type && action.type.startsWith('user/')) {

    const stateJSON = JSON.stringify(store.getState().user)

    sessionStorage.setItem('eop-web-app', stateJSON)

  }

 

  return result

}

 

export const refresh = createAction('refresh')

 

export const PersistSuspense = ({ children }) => {

  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()

 

  useEffect(() => {

    const state = sessionStorage.getItem('eop-web-app')

    if (state) {

      dispatch(refresh(JSON.parse(state)))

    }

    setLoading(false)

  }, [dispatch])

 

  return (

    loading ? <></> : children

  )

}

 

export default persist