import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAncestors,
  setSelAncestor,
  selAncestor,
  pendingLoad
} from './../../containers/ancestor/ancestorSlice';
import LoadingIndicator from './../../share/LoadingIndicator'
import {
  setSelAncestorEssays
} from './../../containers/essay/essaySlice';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import { isNumeric } from './../../../util/LangUtils'
import { push } from 'connected-react-router'
import {
 clearPlaces,
 setSelAncestorBirthPlace,
 setSelAncestorDeathPlace,
} from './../../containers/place/placeSlice';

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function AncestorMenu(props) {
     const ancestors = useSelector(selectAncestors);
     const ancestor = useSelector(selAncestor)
     const dispatch = useDispatch();
     const classes = useStyles();
     const pendingload = useSelector(pendingLoad);
    
    
     const formatAncestors = (ancs) =>{
        let children = new Array()
        ancs.forEach((anc)=>{
            children.push({
                'id': anc.ancestorId,
                'name': anc.firstName + (' ' + anc.middleName + ' ' || ' ') + (' ' + anc.maidenName + ' ' || ' ') +anc.lastName,
                })
            })
        return children
    }

    function nodeClick(event, nodeId) {
        if(isNumeric(nodeId)){
            dispatch(setSelAncestor(nodeId))
            dispatch(setSelAncestorEssays(nodeId))
            dispatch(push('/ancestor'))
            props.toggle();
        }
        else if(nodeId != 'root'){
            //alert('Family Is ' + nodeId)
            
        }
        else{
            //alert('ROOT')
            
        }

        
    }


    const formatAncestorMenu = () =>{
        let lnames = [...new Set(ancestors.map(item => item.lastName))];
       // console.log(lnames)
        let ret = ''
     
        let menuData = new Array()


        lnames.forEach((name)=>{
            let node = {
                'id': name,
                'name': name,
                'children': formatAncestors(ancestors.filter(el => el.lastName.indexOf(name) !== -1))
            }

            menuData.push(node)

        })

       
       let data = {
        id: 'root',
        name: 'Families',
        children: menuData
        };

        //console.log(menuData)
        return data;


    }

    const renderTree = (nodes) => (
       // console.log(nodes)
        <TreeItem key={nodes.name} nodeId={nodes.id} label={nodes.name} >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>

    );


 return(
     <div> 
    
     {pendingload?<LoadingIndicator />:''}
     {/* {(()=>{ if(pendingLoad == true){
                
                        return <LoadingIndicator />
                    }
                    else{
                        return <div>hello</div>
                    }})} */}
         <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={nodeClick}
            >
            
            {renderTree(formatAncestorMenu())}
 
            
          </TreeView>
     </div>
 )
    

}


