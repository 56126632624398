import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import config from '../../env/config'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginRight: theme.spacing(2),
      borderColor: 'darkblue',
      padding: '2px',
      border: '2px solid'
    },
  },
}));

export default function ThumNailDelete(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Badge onClick={()=> props.deleteImage(props.imageId)} badgeContent={'X'} color="secondary" />
        <img src={ config.ENDPOINT + '/imageservice/fetchimage/' + props.imageId } style={{'width' : '100px'}} />
    </div>
  );
}