import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import ancestorReducer from './containers/ancestor/ancestorSlice';
import placeReducer from './containers/place/placeSlice';
import userReducer from './containers/user/UserSlice';
import essayReducer from './containers/essay/essaySlice';
import loadingReducer from './share/LoadingSlice';
import imageReducer from './containers/image/imageSlice';
import { combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import DevTools from './DevTools'
import { persistStore } from 'redux-persist'
import { loadState, saveState } from './localstorage'
import persist from './persist'

export const history = createBrowserHistory({basename: '/'})

//const logger = createLogger({});

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  counter: counterReducer,
  ancestors: ancestorReducer,
  users: userReducer,
  places: placeReducer,
  essays: essayReducer,
  loader: loadingReducer,
  images: imageReducer
})

const localStorageMiddleware = ({getState}) => { // <--- FOCUS HERE
    return (next) => (action) => {
        const result = next(action);
        localStorage.setItem('applicationState', JSON.stringify(
            getState()
        ));
        return result;
    };
};


const reHydrateStore = () => { // <-- FOCUS HERE

    if (localStorage.getItem('applicationState') !== null) {
        return JSON.parse(localStorage.getItem('applicationState')) // re-hydrate the store

    }
}

export const store = function configureStore() {
  const store = createStore(
    createRootReducer(history),
    reHydrateStore(), 
    compose(
      applyMiddleware(routerMiddleware(history), thunk, createLogger(), localStorageMiddleware),
      DevTools.instrument()
      )
  )

  //const persistor = persistStore(store)
  return store
}
