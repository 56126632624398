import 'isomorphic-fetch'
import config from '../../../env/config'
import { setLoader } from '../../../app/share/LoadingSlice'
export const fetchAllAncestors = async () => {
   return fetch(config.ENDPOINT + '/ancestorservice/selectAll', 
            {method: 'get'})
            .then((res) => res.json())
            .then((res) => res.data)
    
}

export const fetchAncestor = async (ancestorId) => {
    return fetch(config.ENDPOINT + '/ancestorservice/fetchAncestorData/' + ancestorId, 
            {method: 'get'})
            .then((res) => res.json())
            .then((res) =>{ return res})
    
}

export const saveAncestor = async (args) => {
    return fetch(config.ENDPOINT + '/ancestorservice/saveAncestor/',     
            {method: 'post',
            body: args.Ancestor,
            headers: new Headers({'Authorization': 'Bearer ' + args.Token,
                                  'content-type': 'application/json'})
            })
            .then((res) => res.json())
            .then((res) =>{ return res})
}

export const fetchProfileImages = async(ancestorId) => {
    console.log('fetching profile images for ' + ancestorId);
     return fetch(config.ENDPOINT + '/imageservice/getImages/' + ancestorId + '/profile', 
            {method: 'get'})
            .then((res) => res.json())
            .then((res) =>{ return res.data})
}
