import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false
};

export const loadingSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
         setLoader: (state, action) => {
           state.loading = action.payload
        },
    },
})

export const loading = (state) => state.loader.loding;
export const { setLoader } = loadingSlice.actions;

export default loadingSlice.reducer;