import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAllAncestors, fetchAncestor, saveAncestor, fetchProfileImages } from './ancestorApi'
import config from '../../../env/config'
const initialState = {
  selAncestor: null,
  selAncestors: [],
  pendingLoad: false,
  pendingAncestorLoad: false,
  saveAncestorResult: [],
  selAncestorProfileImages: [],
  addAncestorDialogOpen: false,
  newAncestor: null
};

export const setSelAncestor = createAsyncThunk(
  'ancestor/setSelAncestor',
  async (ancestorId: string) => {
    const response = await fetchAncestor(ancestorId);
    return await response
  }
);

export const getSelectedProfileImages = createAsyncThunk(
  'ancestor/getSelectedProfileImages',
    async (ancestorId: string) => {
    const response = await fetchProfileImages(ancestorId);
    return await response
  }
)

export const fetchAsync = createAsyncThunk(
  'ancestor/fetchAsync',
  async () => {
    const response = await fetchAllAncestors();
    return await response
  }
);



export const saveLocalAncestor = createAsyncThunk(
  'ancestor/saveLocalAncestor',
  async (args) => {
     console.log('IN THUNK')
     console.log(args.Token)
    const response = await saveAncestor(args);
    return await response
  }
);


export const ancestorSlice = createSlice({
    name: 'ancestors',
    initialState,
    reducers: {
        fetchAll: (state, action) => {
           state.selAncestors = action.payload
        },
        updateAncestor: (state, action) =>{
          state.selAncestor.data.ancestor.[action.payload.property] = action.payload.value;
        },
        updateNewAncestor: (state, action) =>{
          if(state.newAncestor){
            state.newAncestor.[action.payload.property] = action.payload.value;
          }
          else{
            state.newAncestor = {};
            let prop = action.payload.property;
            let val = action.payload.value;
            Object.assign(state.newAncestor, {prop: val});
          }
        },
        setAddAncestorDialogState: (state, action) =>{
          state.addAncestorDialogOpen = action.payload;
        }    

    },
    extraReducers: (builder) => {
    builder
      .addCase(fetchAsync.fulfilled, (state, action) => {
        state.selAncestors = action.payload;
        state.pendingLoad = false;
      })
      .addCase(fetchAsync.pending, (state, action) => {
        state.pendingLoad = true;
      })
      .addCase(setSelAncestor.fulfilled, (state, action) => {
         state.selAncestor = action.payload;
         state.pendingAncestorLoad = false;
      })
      .addCase(setSelAncestor.pending, (state, action) => {
         state.pendingAncestorLoad = true;
      })
      .addCase(saveLocalAncestor.fulfilled, (state, action) => {
         state.saveAncestorResult = action.payload;
      })
      .addCase(getSelectedProfileImages.fulfilled, (state, action) =>{
        state.selAncestorProfileImages = action.payload
      });
  },
})

export const selectAncestors = (state) => state.ancestors.selAncestors || [];
export const selAncestor = (state) => state.ancestors.selAncestor || [];
export const pendingLoad = (state) => state.ancestors.pendingLoad;
export const pendingAncestorLoad = (state) => state.ancestors.pendingAncestorLoad;
export const selAncestorProfileImages = (state) => state.ancestors.selAncestorProfileImages || [];
export const addDialogOpen = (state) => state.ancestors.addAncestorDialogOpen;
export const newancestor = (state) => state.newAncestor || {}
export const { fetchAll, updateAncestor, setAddAncestorDialogState, updateNewAncestor } = ancestorSlice.actions;

export default ancestorSlice.reducer;