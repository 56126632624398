import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ReactHtmlParser from 'react-html-parser';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { push } from 'connected-react-router';
import {
  updateAncestor,
  selAncestor,
  setSelAncestor,
  pendingAncestorLoad,
  saveLocalAncestor,
  selectAncestors,
  selAncestorProfileImages,
  getSelectedProfileImages
} from './../../containers/ancestor/ancestorSlice';
import {
  setSelAncestorBirthPlace,
  setSelAncestorDeathPlace,
  selAncestorBirthplace,
  selAncestorDeathplace,
  fetchAllPlaces,
  allPlaces
} from './../../containers/place/placeSlice';
import { saveImage, removeImage } from './../../containers/image/imageSlice'
import ThumbNailDelete from '../../share/ThumbNailDelete'
import {
  selectUser
} from './../../containers/user/UserSlice';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PortraitOutlinedIcon from '@material-ui/icons/PortraitOutlined';
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { formatName } from './../../../util/LangUtils';
import Dropzone from 'react-dropzone';
import config from './../../../env/config'
import {
  selectEssays,
  setSelAncestorEssays,
  setSelEssay
} from './../../containers/essay/essaySlice';
const useStyles = makeStyles((theme) => ({
    nameDiv: {
        fontSize: 34,
        marginBottom: 20,
        marginTop:20
    },
    imagesDiv: {
        fontSize: 34,
        marginBottom: 20,
        marginTop:20,
        padding: '2px'

    },
    ancestorDiv: {
        fontSize: 34,
        marginBottom: 20,
        marginTop:20
    },
    dateDiv: {
      display: 'flex',
      marginBottom: 10
    },
    avatarDiv: {
      display: 'flex'
    },
    placeMenu: {
      display: 'flex',

    },
    profileImagesDiv: {
      display: 'flex'
    },
    profileImageDiv: {
      padding: '5px'
    },
    placeLabel: {
     align: 'center',
     textAlign: 'right'
    },
    parentDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    childrenDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    spouseDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    upldImage: {
      height: '100px',
      width: '100px',
      backgroundColor: 'lightblue',
      border: 'dashed',
      borderColor: 'darkblue',
      fontSize: 14,
      marginTop: '20px',
      padding: '5px'
    },
    nameMenu: {
      width: 350
      
    },
    essayDiv: {

    }

}));


export default function AncestorEditPage() {
    const ancestorData = useSelector(selAncestor);
    const ancestorBirthPlace = useSelector(selAncestorBirthplace);
    const ancestorDeathPlace = useSelector(selAncestorDeathplace);
    const allplaces = useSelector(allPlaces);
    const classes = useStyles();
    const dispatch = useDispatch();
    const pendingancestorload = useSelector(pendingAncestorLoad);
    const user = useSelector(selectUser);
    const selAncestors = useSelector(selectAncestors);
    const profileImages = useSelector(selAncestorProfileImages);
    const ancestorEssays = useSelector(selectEssays);

    useEffect(() => {
      dispatch(fetchAllPlaces());
      dispatch(getSelectedProfileImages(ancestorData.data.ancestor.ancestorId));
      dispatch(setSelAncestorEssays(ancestorData.data.ancestor.ancestorId));
    }, []);

    const saveUpdate = () =>{
      //alert(user.tokenObj.expires_at + ' | ' + new Date() *1) 
      console.log('Calling saveLocalAncestor with ' + JSON.stringify(ancestorData.data.ancestor) + ' and ' + user.tokenObj.access_token)
      dispatch(saveLocalAncestor({'Ancestor': JSON.stringify(ancestorData.data.ancestor), 'Token': user.tokenId}));
    }

    const deleteProfileImage = (imageId) =>{
      //alert('deleting image ' + imageId)
       new Promise((resolve,reject)=>{
          let ret = dispatch(removeImage({'imageId': imageId, 'token': user.tokenId}))
          resolve(ret)
       })
       .then((result)=>{
         dispatch(getSelectedProfileImages(ancestorData.data.ancestor.ancestorId));
       })
    }

    const setTheEssay = (essay) => {
       dispatch(setSelEssay(essay))
        dispatch(push('/essayEdit'))
    }

    const initNewEssay = () =>{
      let newEssay = {'heading': 'ancestor_essay',
                      'headline':'Type a Headline',
                      'inputDTM': new Date(),
                      'essayText':'',
                      'subHeading':ancestorData.data.ancestor.ancestorId,
                      'subheadHome':'no'
                      }
      dispatch(setSelEssay(newEssay))
      dispatch(push('/essayEdit'))
    }

    const prepareImageForUpload = (acceptedFiles)=>{
      //determine sequence id
      let seqId = 0;
      if(profileImages.length > 0){
        seqId = profileImages.reduce((prev, current) => (prev.sequence > current.sequence) ? prev : current).sequence + 1;
      }
      else{
        seqId = 1;
      }

      console.log('New Sequence is ' + seqId);

      acceptedFiles.map((file)=>{
        new Promise((resolve,reject)=>{
          let reader = new FileReader();
          reader.readAsDataURL(file)
          reader.onload = () =>{
            if(!!reader.result){
              resolve (reader.result);
            }
            else{
              reject (Error("Failed converting Base64"));
            }
          }
        })
        .then((result)=>{
          file.dataUrl = result.split(',')[1];
          return dispatch(saveImage({'parentId' : ancestorData.data.ancestor.ancestorId,
                              'sequence' : seqId,
                              'subheading' : 'profile',
                              'file' : file.dataUrl,
                              'token': user.tokenId,
                              'filetype': file.type}))
          //uploadProfileImage(file, ancestorData.data.ancestor.ancestorId);
          //console.log(file)
        }).then((result)=>{
          dispatch(getSelectedProfileImages(ancestorData.data.ancestor.ancestorId));
        })
        
      })
  }


     return (
        <div  className={classes.ancestorDiv}>
        <div className={classes.avatarDiv}>
        <Avatar>
            <AccountCircleIcon />
        </Avatar>
        Ancestor
        </div>
         <Divider variant="inset"/>
        <div className={classes.nameDiv}>
              <TextField
              id="firstName"
              label="First Name"
              value={ancestorData.data.ancestor.firstName}
              onChange={ (event) => dispatch(updateAncestor({value: event.target.value, property: 'firstName'}))}
            />
            <TextField
              id="middleName"
              label="Middle Name"
              value={ancestorData.data.ancestor.middleName} 
              onChange={ (event) => dispatch(updateAncestor({value: event.target.value, property: 'middleName'}))}
            />
            <TextField
              id="maidenName"
              label="Maiden Name"
              value={ancestorData.data.ancestor.maidenName}
              onChange={ (event) => dispatch(updateAncestor({value: event.target.value, property: 'maidenName'}))}
            />
            <TextField
              id="lastName"
              label="Last Name"
              value={ancestorData.data.ancestor.lastName}
              onChange={ (event) => dispatch(updateAncestor({value: event.target.value, property: 'lastName'}))}
            />
        </div>
        <div className={classes.dateDiv}>
          <TextField
              id="birthDate"
              label="Birth Date"
              value={ancestorData.data.ancestor.birthDate}
              onChange={ (event) => dispatch(updateAncestor({value: event.target.value, property: 'birthDate'}))}
            /> 
            <TextField
              select
              labelId="birthPlace"
              id="birthPlace"
              label="Birth Place"
              className={classes.nameMenu}
              value={ancestorData.data.ancestor.birthPlace}
              onChange={ (event) => dispatch(updateAncestor({value: event.target.value, property: 'birthPlace'}))}
            >
              <MenuItem value={'0'}>None Selected</MenuItem>
              {allplaces.map((place) =>{
                return <MenuItem value={place.placeId}>{place.placeName}</MenuItem>
              })}
            </TextField>

        </div>
         <div className={classes.dateDiv}>
          <TextField
              id="deathDate"
              label="Death Date"
              value={ancestorData.data.ancestor.deathDate}
              onChange={ (event) => dispatch(updateAncestor({value: event.target.value, property: 'deathDate'}))}
            /> 
            <TextField
              select
              labelId="deathPlace"
              id="deathPlace"
              label="Death Place"
              className={classes.nameMenu}
              value={ancestorData.data.ancestor.deathPlace}
              onChange={ (event) => dispatch(updateAncestor({value: event.target.value, property: 'deathPlace'}))}
            >
              <MenuItem value={'0'}  className={classes.placeMenu}>None Selected</MenuItem>
              {allplaces.map((place) =>{
                return <MenuItem className={classes.placeMenu} value={place.placeId}>{place.placeName}</MenuItem>
              })}
            </TextField>
          </div>
          <div className={classes.imagesDiv}>
              <div className={classes.avatarDiv}>
              <Avatar color='lightblue'>
                <PortraitOutlinedIcon />
              </Avatar>
              Profile Photos
              <Divider variant="inset"/>
              </div>
              <div className={classes.profileImagesDiv}>
              
              {/* {profileImages.map((profimage)=>{
                return <div className={classes.profileImageDiv}><img src={ config.ENDPOINT + '/imageservice/fetchimage/' + profimage.imageId } style={{'width' : '100px'}} /></div>
              })} */}
              {profileImages.map((profimage)=>{
                return <ThumbNailDelete deleteImage={deleteProfileImage} imageId={profimage.imageId} />
              })}
              <Dropzone onDrop={acceptedFile => prepareImageForUpload(acceptedFile)}>
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()} className={classes.upldImage}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop profile images, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              </div>
          </div>
          <div>
            <div className={classes.avatarDiv}>
              <Avatar>
                <SupervisedUserCircleIcon />
              </Avatar>
              Parents 
              <Divider variant="inset"/>
            </div>
            <div></div>
            <div className={classes.dateDiv}>
              {/* <InputLabel className={classes.placeLabel} id="father">Father     </InputLabel> */}
              <TextField
                select
                labelId="father"
                id="father"
                label="Father"
                className={classes.nameMenu}
                value={ancestorData.data.ancestor.fatherId}
                onChange={ (event) => dispatch(updateAncestor({value: event.target.value, property: 'fatherId'}))}
              >
                <MenuItem value={'0'}  className={classes.placeMenu}>None Selected</MenuItem>
                {selAncestors.map((anc) =>{
                  return <MenuItem className={classes.placeMenu} value={anc.ancestorId}>{formatName(anc)}</MenuItem>
                })}
              </TextField>
            </div>
            <div className={classes.dateDiv}>
              {/* <InputLabel className={classes.placeLabel} id="mother">Mother     </InputLabel> */}
              <TextField
                select
                className={classes.nameMenu}
                labelId="mother"
                id="mother"
                label="Mother"
                value={ancestorData.data.ancestor.motherId}
                onChange={ (event) => dispatch(updateAncestor({value: event.target.value, property: 'motherId'}))}
              >
                <MenuItem value={'0'}  className={classes.placeMenu}>None Selected</MenuItem>
                {selAncestors.map((anc) =>{
                  return <MenuItem value={anc.ancestorId}>{formatName(anc)}</MenuItem>
                })}
              </TextField>
            </div>
          </div>
          <div>
                <Button variant="contained" color="primary" onClick={() => saveUpdate()}>
                  Save Updates
                </Button>
          </div>
               <div>
                    {ancestorEssays?
                        <div>{
                        ancestorEssays.map((essay)=>{
                            return<div>
                                        { ReactHtmlParser(essay.headline) }
                                        <EditOutlinedIcon onClick={() => setTheEssay(essay)}/>
                                  </div>
                        })
                    }</div>:''}
                </div>
                <div>
                    <Button variant="contained" color="primary" onClick={() => initNewEssay()}>
                      Add Essay
                    </Button>
                </div>
     </div>
     )

}