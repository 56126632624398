import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';

import {
  selectUser,
  registerUser
} from './../../containers/user/UserSlice';


export default function Login() {
const user = useSelector(selectUser);
const dispatch = useDispatch();

const responseGoogle = (response) => {
   dispatch(registerUser(response));
   let logoutinterval = (response.tokenObj.expires_in || 3600 * 60) * 1000;
   console.log(response.tokenObj.expires_in)
   setTimeout(()=>{ 
      if(user.tokenObj.expiresAt < new Date()*1){
        dispatch(registerUser([])); 
      }
     
     }, logoutinterval);
}


const failureGoogle = (response) =>{
 console.log(response);
}

return (
    <div> 

      <GoogleLogin
        clientId="926070336531-ffkhob8ar2f0ndagijimd4q41bqmci2e.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={failureGoogle} 
        cookiePolicy={'single_host_origin'}/>

    </div>
)

}


