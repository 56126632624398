import React, {useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import {
  fetchAllPlaces,
  allPlaces,
  setAddPlaceDialogState,
  addPlaceDialogOpen,
} from './../../containers/place/placeSlice';
import Slide from '@material-ui/core/Slide';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    nameDiv: {
        fontSize: 34,
        marginBottom: 20,
        marginTop:20
    },
    imagesDiv: {
        fontSize: 34,
        marginBottom: 20,
        marginTop:20,
        padding: '2px'

    },
    ancestorDiv: {
        fontSize: 34,
        marginBottom: 20,
        marginTop:20
    },
    dateDiv: {
      display: 'flex',
      marginBottom: 10
    },
    containerDiv: {
        align: 'center',
        padding: 12
    },
    actionDiv: {
        textAlign: 'center',
        padding: 5,
        width: '100%'
    },
    avatarDiv: {
      display: 'flex'
    },
    placeMenu: {
      display: 'flex',

    },
    profileImagesDiv: {
      display: 'flex'
    },
    profileImageDiv: {
      padding: '5px'
    },
    placeLabel: {
     align: 'center',
     textAlign: 'right'
    },
    parentDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    childrenDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    spouseDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    upldImage: {
      height: '100px',
      width: '100px',
      backgroundColor: 'lightblue',
      border: 'dashed',
      borderColor: 'darkblue',
      fontSize: 14,
      marginTop: '20px',
      padding: '5px'
    },
    nameMenu: {
      width: 350
      
    },
    essayDiv: {

    }

}));

export default function PlaceAddDialog(props) {
    const dispatch = useDispatch();
    const pldlgopen = useSelector(addPlaceDialogOpen);
    const classes = useStyles();
    const allplaces = useSelector(allPlaces);
    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction='up' ref={ref} {...props} />;
    // })

    useEffect(() => {
      dispatch(fetchAllPlaces());
    }, []);

        
    return(
        <div>
            <Dialog 
            open={pldlgopen}
            //TransitionComponent={Transition}
            contentStyle={{
                width: '75%',
                maxWidth: 'none'
            }}
            fullWidth={true}
            onClose={() => dispatch(setAddPlaceDialogState(false))}
            aria-labelledby='Add Place'
            areia-describedby='add a place'
            >
                <AppBar position='static'>
                    <table width='100%'>
                        <tbody>
                            <tr>
                                <td width='33%'></td>
                                <td width='34%' align='center'>Add Place</td>
                                <td width='33%' align='right'>
                                    <IconButton>
                                        <CloseIcon onClick={() => dispatch(setAddPlaceDialogState(false))} />
                                    </IconButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </AppBar>
                <div className={classes.containerDiv}>
                    
                    
                    <div className={classes.actionDiv}>
                        <Button variant="contained" color="primary">
                        Save
                        </Button>
                        &nbsp;
                        <Button variant="contained" color="primary">
                        Save & Edit
                        </Button>
                     </div>
                </div>
            </Dialog>
        </div>
    )

}
