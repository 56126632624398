import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {
  selAncestor,
  setSelAncestor,
  pendingAncestorLoad
} from './../../containers/ancestor/ancestorSlice';
import {
  setSelAncestorBirthPlace,
  setSelAncestorDeathPlace,
  selAncestorBirthplace,
  selAncestorDeathplace
} from './../../containers/place/placeSlice';
import {
  selectEssays,
  setSelAncestorEssays
} from './../../containers/essay/essaySlice';
import {
  selectUser
} from './../../containers/user/UserSlice';
import LoadingIndicator from './../../share/LoadingIndicator';
import ShowMoreText from "react-show-more-text";
import ReactHtmlParser from 'react-html-parser';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import config from './../../../env/config'

const useStyles = makeStyles((theme) => ({
    nameDiv: {
        fontSize: 34,
    },
    parentDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    childrenDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    spouseDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    essayDiv: {

    },
    essayHeadline: {
      fontSize: 24,
      color: '#3F51B5',
      fontWeight: 'bolder',
      marginTop: '20px'
    },
    infoDiv: {
      display: 'flex',
      width:'800px'
    }

}));

export default function AncestorPage() {
    const ancestorData = useSelector(selAncestor);
    const ancestorEssays = useSelector(selectEssays);
    const ancestorBirthPlace = useSelector(selAncestorBirthplace);
    const ancestorDeathPlace = useSelector(selAncestorDeathplace);
    const classes = useStyles();
    const dispatch = useDispatch();
    const pendingancestorload = useSelector(pendingAncestorLoad);
    const user = useSelector(selectUser);
    const jumpToIt =(id) =>{
        dispatch(setSelAncestor(id))
        dispatch(setSelAncestorEssays(id))
    }

     return (
     <div>
        {pendingancestorload?<LoadingIndicator />:''}
     {ancestorData.data?
     <div>          <div className={classes.nameDiv}>
                            { ancestorData.data.ancestor.firstName + (' ' + ancestorData.data.ancestor.middleName + ' ' || ' ') + (' ' + ancestorData.data.ancestor.maidenName + ' ' || ' ') + ancestorData.data.ancestor.lastName } 
                            {user.tokenObj?<Link href='/ancestoredit'><EditOutlinedIcon/></Link>:''}
                            {ancestorData.data.profileImages.map((profimage)=>{
                                return <img src={ config.ENDPOINT + '/imageservice/fetchimage/' + profimage.imageId } style={{'width' : '200px', 'float':'right'}} />
                            })}

                    </div>
                    <div>
                            b. {ancestorData.data.ancestor.birthDate || '???'} { ancestorData.data.birthPlace? ancestorData.data.birthPlace.placeName: '' } 
                    </div>
                    <div>
                            d. {ancestorData.data.ancestor.deathDate || '???'} { ancestorData.data.deathPlace? ancestorData.data.deathPlace.placeName: '' }
                    </div>
         <div className={classes.parentDiv}>
         {ancestorData.data.father?<Typography>Father: <Link href="#" onClick={()=>jumpToIt(ancestorData.data.father.ancestorId)}>{ancestorData.data.father.firstName 
                                                    + (' ' + ancestorData.data.father.middleName + ' ' || ' ') 
                                                    + (' ' + ancestorData.data.father.maidenName + ' ' || ' ') 
                                                    + ancestorData.data.father.lastName 
                                                    + (' b.' + ancestorData.data.father.birthDate + ' ' || ' ')
                                                    + (' d.' + ancestorData.data.father.deathDate + ' ' || ' ')}</Link></Typography>:''

         }
         {ancestorData.data.mother?<Typography>Mother: <Link href="#" onClick={()=>jumpToIt(ancestorData.data.mother.ancestorId)}>{ancestorData.data.mother.firstName 
                                                    + (' ' + ancestorData.data.mother.middleName + ' ' || ' ') 
                                                    + (' ' + ancestorData.data.mother.maidenName + ' ' || ' ') 
                                                    + ancestorData.data.mother.lastName 
                                                    + (' b.' + ancestorData.data.mother.birthDate + ' ' || ' ')
                                                    + (' d.' + ancestorData.data.mother.deathDate + ' ' || ' ')}</Link></Typography>:''

         }

         </div>
         <div className={classes.spouseDiv}>
            Spouses:
            {ancestorData.data.spouses.map((spouse)=>{
                return <div><Link href="#" onClick={()=>jumpToIt(spouse.spouse.ancestorId)}>{spouse.spouse.firstName 
                                                    + (' ' + spouse.spouse.middleName + ' ' || ' ') 
                                                    + (' ' + spouse.spouse.maidenName + ' ' || ' ') 
                                                    + spouse.spouse.lastName 
                                                    + (' m.' + spouse.marriageDate + ' ' || '???')}{spouse.marriagePlace? ' ' + spouse.marriagePlace.placeName:''}</Link></div>
            })

            }
         </div>
         <div className={classes.childrenDiv}>
            Children:
            {ancestorData.data.children.map((child)=>{
               return <div><Link href="#" onClick={()=>jumpToIt(child.ancestorId)}>{child.firstName 
                                                    + (' ' + child.middleName + ' ' || ' ') 
                                                    + (' ' + child.maidenName + ' ' || ' ') 
                                                    + child.lastName 
                                                    + (' b.' + child.birthDate + ' ' || ' ')
                                                    + (' d.' + child.deathDate + ' ' || ' ')}</Link></div>
            })

            }
         </div>
     </div>
     :<div></div>}
     <div className={classes.essayDiv}>
        {ancestorEssays?
            <div>{
            ancestorEssays.map((essay)=>{
                return <div><div className={classes.essayHeadline}>{essay.headline}</div>
                            <div>{ ReactHtmlParser(essay.essayText)}</div>
                       </div>
            })
        }</div>:''}
     </div>
     </div>
     )
     

}