import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAncestorEssays, saveEssay } from './essayApi';


const initialState = {
  selEssays: [],
  selEssay:{}
};


export const saveAndUpdate = createAsyncThunk(
  'essays/saveAndUpdate',
  async (args) =>{
    const response = await saveEssay(args)
    return await response;
  })


export const setSelAncestorEssays = createAsyncThunk(
  'essays/setSelAncestor',
  async (ancestorId: string) => {
    const response = await fetchAncestorEssays(ancestorId);
    return await response;
  }
);

export const essaySlice = createSlice({
    name: 'essays',
    initialState,
    reducers: {
        setSelEssay: (state, action) => {
           state.selEssay = action.payload
        },
        updateEssay: (state, action) =>{
          state.selEssay.[action.payload.property] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
    builder
      .addCase(setSelAncestorEssays.fulfilled, (state, action) => {
        state.selEssays = action.payload;
      })
      .addCase(saveAndUpdate.fulfilled, (state, action)=>{
        state.selEssay = action.payload;
      });
  },
})

export const selectEssays = (state) => state.essays.selEssays || [];
export const selectEssay = (state) => state.essays.selEssay || {};
export const { setSelEssay, updateEssay } = essaySlice.actions;
export default essaySlice.reducer;
