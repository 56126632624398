import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import {
  selectUser
} from './../../containers/user/UserSlice';
import {
  selectEssays,
  setSelAncestorEssays,
  setSelEssay,
  selectEssay,
  updateEssay,
  saveAndUpdate
} from './../../containers/essay/essaySlice';
import { uploadImage } from './../../containers/image/imageApi';
import config from './../../../env/config';
import TextField from '@material-ui/core/TextField';

 export default function EssayEditPage() {
   const editorRef = useRef(null);
   const slEssay = useSelector(selectEssay)
   const user = useSelector(selectUser);
   const dispatch = useDispatch();
   const log = () => {
     if (editorRef.current) {
       console.log(editorRef.current.getContent());
     }
   };

   const SaveThisEssay = () => {
        dispatch(saveAndUpdate({'Essay': JSON.stringify(slEssay), 'Token': user.tokenId}))
   }

   const updateContent = (event) => {
      if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      dispatch(updateEssay({value: editorRef.current.getContent(), property: 'essayText'}))
     }
   }
    return (
        <>
        <TextField
              id="headline"
              label="Headline"
              value={slEssay.headline}
              onChange={ (event) => dispatch(updateEssay({value: event.target.value, property: 'headline'}))}
            />
       <Editor
         apiKey="br2pjp2h52qnalt09e2ac2x0igb9woh80m85pibi2q01jl3f"
         onInit={(evt, editor) => editorRef.current = editor}
         initialValue={slEssay.essayText}
         selector ='textarea'  // change this value according to your HTML
         onBlur={ (event) => updateContent(event)}
         images_upload_url = 'postAcceptor.php'
         images_upload_base_path =  '/some/basepath'
         init={{
           height: 500,
           menubar: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount image code'
           ],
           toolbar: 'undo redo | formatselect | ' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ' +
           'removeformat | help | image code',
           images_upload_handler: function (blobInfo, success, failure) {
                //setTimeout(function () {
                
                new Promise((resolve,reject)=>{
                    let ret = uploadImage({'parentId' : slEssay.essayId,
                              'sequence' : 0,
                              'subheading' : 'essay',
                              'file' : blobInfo.base64(),
                              'token': user.tokenId,
                              'filetype': blobInfo.blob().type})
                    resolve(ret)
                })
                .then((result)=>{
                   success(config.ENDPOINT + '/imageservice/fetchimage/' + result);
                })
                
                //}, 10000);
            },
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
         }}
       />
       <button onClick={() => SaveThisEssay()}>Save and Update</button>
     </>

    );

 }

