import React, {useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import {
  updateNewAncestor,
  setAddAncestorDialogState,
  addDialogOpen,
  newancestor
} from './../../containers/ancestor/ancestorSlice';
import {
  fetchAllPlaces,
  allPlaces
} from './../../containers/place/placeSlice';
import Slide from '@material-ui/core/Slide';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    nameDiv: {
        fontSize: 34,
        marginBottom: 20,
        marginTop:20
    },
    imagesDiv: {
        fontSize: 34,
        marginBottom: 20,
        marginTop:20,
        padding: '2px'

    },
    ancestorDiv: {
        fontSize: 34,
        marginBottom: 20,
        marginTop:20
    },
    dateDiv: {
      display: 'flex',
      marginBottom: 10
    },
    containerDiv: {
        align: 'center',
        padding: 12
    },
    actionDiv: {
        textAlign: 'center',
        padding: 5,
        width: '100%'
    },
    avatarDiv: {
      display: 'flex'
    },
    placeMenu: {
      display: 'flex',

    },
    profileImagesDiv: {
      display: 'flex'
    },
    profileImageDiv: {
      padding: '5px'
    },
    placeLabel: {
     align: 'center',
     textAlign: 'right'
    },
    parentDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    childrenDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    spouseDiv: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    upldImage: {
      height: '100px',
      width: '100px',
      backgroundColor: 'lightblue',
      border: 'dashed',
      borderColor: 'darkblue',
      fontSize: 14,
      marginTop: '20px',
      padding: '5px'
    },
    nameMenu: {
      width: 350
      
    },
    essayDiv: {

    }

}));


export default function AncestorAddDialog(props) {
    const dispatch = useDispatch();
    const dlgopen = useSelector(addDialogOpen);
    const newAnc = useSelector(newancestor);
    const classes = useStyles();
    const allplaces = useSelector(allPlaces);
    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction='up' ref={ref} {...props} />;
    // })

    useEffect(() => {
      dispatch(fetchAllPlaces());
    }, []);

    const saveNewAncestor = (forward) => {

    }
    
    return(
        <div>
            <Dialog 
            open={dlgopen}
            //TransitionComponent={Transition}
            contentStyle={{
                width: '75%',
                maxWidth: 'none'
            }}
            fullWidth={true}
            onClose={() => dispatch(setAddAncestorDialogState(false))}
            aria-labelledby='Add Ancestor'
            areia-describedby='add an ancestor'
            >
                <AppBar position='static'>
                    <table width='100%'>
                        <tbody>
                            <tr>
                                <td width='33%'></td>
                                <td width='34%' align='center'>Add Ancestor</td>
                                <td width='33%' align='right'>
                                    <IconButton>
                                        <CloseIcon onClick={() => dispatch(setAddAncestorDialogState(false))} />
                                    </IconButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </AppBar>
                <div className={classes.containerDiv}>
                    <div className={classes.nameDiv}>
                        <TextField
                        id="firstName"
                        label="First Name"
                        value={newAnc.firstName}
                        onChange={ (event) => dispatch(updateNewAncestor({value: event.target.value, property: 'firstName'}))}
                        />
                        <TextField
                        id="middleName"
                        label="Middle Name"
                        value={newAnc.middleName} 
                        onChange={ (event) => dispatch(updateNewAncestor({value: event.target.value, property: 'middleName'}))}
                        />
                        <TextField
                        id="maidenName"
                        label="Maiden Name"
                        value={newAnc.maidenName}
                        onChange={ (event) => dispatch(updateNewAncestor({value: event.target.value, property: 'maidenName'}))}
                        />
                        <TextField
                        id="lastName"
                        label="Last Name"
                        value={newAnc.lastName}
                        onChange={ (event) => dispatch(updateNewAncestor({value: event.target.value, property: 'lastName'}))}
                        />
                    </div>
                    <div className={classes.dateDiv}>
                    <TextField
                        id="birthDate"
                        label="Birth Date"
                        value={newAnc.birthDate}
                        onChange={ (event) => dispatch(updateNewAncestor({value: event.target.value, property: 'birthDate'}))}
                        /> 
                        <TextField
                        select
                        labelId="birthPlace"
                        id="birthPlace"
                        label="Birth Place"
                        className={classes.nameMenu}
                        value={newAnc.birthPlace}
                        onChange={ (event) => dispatch(updateNewAncestor({value: event.target.value, property: 'birthPlace'}))}
                        >
                        <MenuItem value={'0'}>None Selected</MenuItem>
                        {allplaces.map((place) =>{
                            return <MenuItem value={place.placeId}>{place.placeName}</MenuItem>
                        })}
                        </TextField>

                    </div>
                    <div className={classes.dateDiv}>
                    <TextField
                        id="deathDate"
                        label="Death Date"
                        value={newAnc.deathDate}
                        onChange={ (event) => dispatch(updateNewAncestor({value: event.target.value, property: 'deathDate'}))}
                        /> 
                        <TextField
                        select
                        labelId="deathPlace"
                        id="deathPlace"
                        label="Death Place"
                        className={classes.nameMenu}
                        value={newAnc.deathPlace}
                        onChange={ (event) => dispatch(updateNewAncestor({value: event.target.value, property: 'deathPlace'}))}
                        >
                        <MenuItem value={'0'}  className={classes.placeMenu}>None Selected</MenuItem>
                        {allplaces.map((place) =>{
                            return <MenuItem className={classes.placeMenu} value={place.placeId}>{place.placeName}</MenuItem>
                        })}
                        </TextField>
                    </div>
                    <div className={classes.actionDiv}>
                        <Button variant="contained" color="primary" onClick={() => saveNewAncestor(false)}>
                        Save
                        </Button>
                        &nbsp;
                        <Button variant="contained" color="primary" onClick={() => saveNewAncestor(true)}>
                        Save & Edit
                        </Button>
                     </div>
                </div>
            </Dialog>
        </div>
    )

}