import 'isomorphic-fetch'
import config from '../../../env/config'

export const saveEssay = async (args) => {
    console.log('essay from the api is ')
    console.log(args.Essay)
     return fetch(config.ENDPOINT + '/essayservice/saveEssay',     
            {method: 'post',
            body: args.Essay,
            headers: new Headers({'Authorization': 'Bearer ' + args.Token,
                                  'content-type': 'application/json'})
            })
            .then((res) => res.json())
            .then((res) =>{ return res.data})
}

export const fetchAncestorEssays = async (ancestorId) => {
        return fetch(config.ENDPOINT + '/essays/search/findEssaysByAncestor?ancestorId=' + ancestorId + '&heading=ancestor_essay', 
            {method: 'get'})
            .then((res) => res.json())
            .then((res) =>{ return res._embedded.essays})
}