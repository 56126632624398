import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uploadImage, deleteImage } from './imageApi'

const initialState = {
  lastSavedImage: null
};

export const saveImage = createAsyncThunk(
  'image/saveImage',
  async (args) => {
    console.log(args)
    const response = await uploadImage(args);
    return await response
  }
);

export const removeImage = createAsyncThunk(
  'image/deleteImage',
  async (args) => {
    const response = await deleteImage(args);
    return await response;
  }
)



export const imageSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
    builder
      .addCase(saveImage.fulfilled, (state, action) => {
        state.lastSavedImage = action.payload;
      });
  },
})
//export const selectEssays = (state) => state.essays.selEssays || [];
export const lastImage = (state) => state.images.lastSavedImage;
export default imageSlice.reducer;