import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


const initialState = {
  user: []
};

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        registerUser: (state, action) => {
           state.user = action.payload;
        },
    }
})

export const selectUser = (state) => state.users.user || [];
export const { registerUser } = userSlice.actions;

export default userSlice.reducer;