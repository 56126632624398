import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { store, history } from '../../app/store';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch  } from 'react-router' // react-router v4/v5
import Counter from '../../features/counter/Counter'
import HomePage from '../../app/pages/homepage/HomePage'
import AncestorPage from '../../app/pages/ancestorpage/AncestorPage'
import AncestorAddDialog from '../../app/pages/ancestoraddpage/AncestorAddDialog';
import PlaceAddDialog from '../../app/pages/placeaddpage/PlaceAddDialog';
import AncestorEditPage from '../../app/pages/ancestoreditpage/AncestorEditPage'
import EssayEditPage from '../../app/pages/essayeditpage/EssayEditPage'
import Login from '../../app/pages/login/Login'
import App from '../../App';
import Link from '@material-ui/core/Link';
import {
  fetchAll,
  selectAncestors,
  fetchAsync,
  setAddAncestorDialogState
} from '../../app/containers/ancestor/ancestorSlice';
import {
  selectUser,
  registerUser
} from '../../app/containers/user/UserSlice';
import {
  setAddPlaceDialogState
} from '../../app/containers/place/placeSlice';
import { push } from 'connected-react-router'

import AncestorMenu from '../../app/pages/menus/AncestorMenu'


const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%"
  },
  appBar: {
    position: "absolute",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      zIndex: theme.zIndex.drawer + 1
    }
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    flexGrow: 1,
    width: drawerWidth,
    height: '100vh',
    [theme.breakpoints.up("md")]: {
      position: "relative"
    },
    marginBottom: '30px'
  },
  menuLink: {
    color: '#ffffff'
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3
  }
}));

export default function EOPApp() {
  const ancestors = useSelector(selectAncestors);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  useEffect(() => {
    dispatch(fetchAsync());
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  const onUnload = () =>{
    
    if(user != null){
      console.log('IN unload Event ' + user.tokenObj.expires_at + ' ' + new Date()*1)
     if(user.tokenObj.expires_at < new Date()*1){
       dispatch(registerUser([]));
     }
    }
    
  }

  const logout = () =>{
     dispatch(registerUser([]));
  }

  const AddAncestor = () =>{
    console.log('adding ancestor');
    closeDrawerToggle();
    dispatch(setAddAncestorDialogState(true))
  }

  const AddPlace = () =>{
    closeDrawerToggle();
    dispatch(setAddPlaceDialogState(true))
  }

  const classes = useStyles();
  
 const [mobileOpen, setMobileOpen] = useState('')
 const [open, setOpen] = useState('')

  const closeDrawerToggle = () => {
        console.log('in close drawer toggle')
        setOpen(false)
  };
  
  const handleDrawerToggle = () => {
        console.log('in handle drawer toggle')
        setMobileOpen(!mobileOpen)
  };

   const drawer = (
      <div>
        <div className={classes.toolbar} />
            <AncestorMenu toggle={handleDrawerToggle} ancestors={ancestors} />
        </div>
    );
    
      const handleClick = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <IconButton  
            edge="start" 
            className={classes.menuButton} 
            color="inherit" 
            aria-label="menu" 
            onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            EOP
          </Typography>
          <IconButton  
            edge="start" 
            className={classes.menuButton} 
            color="inherit" 
            aria-label="menu" 
            onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={open}
            keepMounted
            open={Boolean(open)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => dispatch(push('/login'))}>Log In</MenuItem>
            <MenuItem onClick={() => logout()}>Log Out</MenuItem>
             {user.tokenObj?<MenuItem onClick={() => AddAncestor()}>Add Ancestor</MenuItem>:''}
              {user.tokenObj?<MenuItem onClick={() => AddPlace()}>Add Place</MenuItem>:''}
          </Menu>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <Drawer
            variant="temporary"
            //anchor={this.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }} 
            //containerStyle={{height: }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <ConnectedRouter history={history}>
         
            <Switch>
                <Route exact path="/" render={() => (<HomePage />)} />
                <Route path="/test" render={() => (<><h1>test</h1></>)} />
                <Route path="/count" component={ Counter } />
                <Route path="/home" component={ HomePage } />
                <Route path="/ancestor" component={ AncestorPage } />
                <Route exact path="/login" component={ Login } />
                <Route path="/ancestoredit" component={ AncestorEditPage } />
                <Route path="/essayEdit" component= { EssayEditPage } />
                <Route render={() => (<div>Miss</div>)} />
            </Switch>
        
         </ConnectedRouter>
        </main>
        <AncestorAddDialog />
        <PlaceAddDialog />
    </div>
  );
}
