import './App.css';

function App() {
  return (
    <div className="App">
      Main Page

      {/* {React.Children.toArray(this.props.children)} */}
    </div>
  );
}

export default App;
